import "./styles.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg";

const mainClass = "message-editor";

const MessageEditor = ({
  content,
  file_progress,
  onSubmit,
  setEditMode,
  attachments,
}) => {
  const { t, i18n } = useTranslation();

  const htmlToDraftBlocks = () => {
    if (!!!htmlToDraft) return;
    const blocksFromHtml = htmlToDraft(content || "");
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [draft_content, setDraftContent] = useState(htmlToDraftBlocks());
  const [files, setFiles] = useState(attachments || []);
  const [files_to_remove, setFilesToRemove] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChange = async (file) => {
    setFiles((prev) => [...prev, ...file]);
  };

  const deleteHandler = (index) => {
    const temp_data = [...files];

    const removed_file = temp_data[index]?.key;

    temp_data?.splice(index, 1);
    setFiles([...temp_data]);
    setFilesToRemove((prev) => [...prev, removed_file]);
  };

  const submitHandler = async () => {
    try {
      setLoading(true);
      await onSubmit(
        draftToHtml(convertToRaw(draft_content.getCurrentContent())),
        files,
        files_to_remove
      );

      if (!!setEditMode) {
        setEditMode(false);
      }

      setDraftContent("");
      setFiles([]);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <div className={mainClass}>
      <Editor
        localization={{ locale: i18n?.language }}
        editorState={draft_content}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={`${mainClass}__editor`}
        placeholder={`${t("Type a message")}...`}
        onEditorStateChange={(editorState) => {
          setDraftContent(editorState);
        }}
        toolbar={{
          options: ["inline", "list", "link", "emoji"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
            bold: { icon: "/wysiwyg/bold.svg", className: undefined },
            italic: { icon: "/wysiwyg/cur.svg", className: undefined },
            underline: { icon: "/wysiwyg/under.svg", className: undefined },
          },
          list: {
            inDropdown: false,
            options: ["unordered", "ordered"],
            unordered: {
              icon: "/wysiwyg/sha_list.svg",
              className: undefined,
            },
            ordered: { icon: "/wysiwyg/num_list.svg", className: undefined },
          },
          link: {
            inDropdown: false,
            defaultTargetOption: "_blank",
            showOpenOptionOnHover: true,
            options: ["link"],
          },
        }}
      />
      <div className={`${mainClass}__action`}>
        <div className={`${mainClass}__action__attachments`}>
          {!!loading && files?.length > 0 && (
            <div className={`${mainClass}__action__attachments__progress`}>
              <div style={{ width: `${file_progress}%` }} />
            </div>
          )}
          <div className={`${mainClass}__action__attachments__list`}>
            {files?.map(({ name }, index) => (
              <div
                key={index}
                className={`${mainClass}__action__attachments__list__item`}
              >
                <div>
                  <span>{name}</span>
                </div>
                <button type="button" onClick={() => deleteHandler(index)}>
                  {t("Delete file")}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className={`${mainClass}__action__buttons`}>
          <label htmlFor="file">
            <AttachmentIcon />
          </label>
          <div className={`${mainClass}__action__buttons__group`}>
            {!!setEditMode && (
              <Button
                size="small"
                onClick={() => setEditMode(false)}
                variant="grey"
              >
                {t("Cancel")}
              </Button>
            )}

            <Button size="small" loading={loading} onClick={submitHandler}>
              {!!setEditMode ? t("Save") : t("Send message")}
            </Button>
          </div>
          <input
            type="file"
            id="file"
            multiple={true}
            onChange={(e) => onChange(e?.target?.files)}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageEditor;
