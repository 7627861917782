import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import api from "libs/api";
import notificationSound from "assets/sounds/notification.mp3";

import { useApp } from "context/app";

let audio = new Audio(notificationSound);

const TaskConversationContext = createContext();

const TaskConversationProvider = ({ children }) => {
  const { user, socket } = useApp();
  const { id } = useParams();

  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file_progress, setFileProgress] = useState(0);

  useEffect(() => {
    (async function () {
      await getTaskConversationData();
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!!socket) return;

    socket.emit("join_task", id);
    socket.on("task_update", (task) => {
      const last_user_id =
        task?.messages?.[task?.messages?.length - 1]?.user?._id;

      setTask({ ...task });

      if (user?._id !== last_user_id) audio.play();
    });

    return () => {
      if (!!!socket) return;

      socket.emit("task_leave", id);
    };

    // eslint-disable-next-line
  }, [socket]);

  const getTaskConversationData = async (refetch = false) => {
    try {
      if (!!!refetch) {
        setLoading(true);
      }
      const task = await api.get(`/webmaster/tasks/${id}/conversation`);
      setTask(task);
    } catch (err) {}

    setLoading(false);
  };

  const sendMessage = async (text, files) => {
    const formData = new FormData();
    formData.append("text", text);
    files?.forEach((item) => {
      formData.append("files", item);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API}/webmaster/tasks/${id}/send-message`,
        formData,
        {
          withCredentials: true,
          onUploadProgress: ({ loaded, total }) => {
            const percent = Math.floor((loaded * 100) / total);
            setFileProgress(percent);
          },
        }
      );
    } catch (err) {
      console.error(err);
    }

    setFileProgress(0);
  };

  const editMessage = async (message_id, text, files, files_to_remove) => {
    try {
      const formData = new FormData();
      formData.append("text", text);
      files_to_remove?.forEach((item) => {
        formData.append("files_to_remove", item);
      });
      files?.forEach((item) => {
        formData.append("files", item);
      });

      await axios.put(
        `${process.env.REACT_APP_API}/webmaster/tasks/${id}/edit-message/${message_id}`,
        formData,
        {
          withCredentials: true,
          onUploadProgress: ({ loaded, total }) => {
            const percent = Math.floor((loaded * 100) / total);
            setFileProgress(percent);
          },
        }
      );

      getTaskConversationData(true);

      // let temp_task = { ...task };

      // const index = temp_task?.messages?.findIndex(
      //   (item) => item?._id === message_id
      // );
      // temp_task.messages[index] = {
      //   ...temp_task.messages[index],
      //   text,
      // };

      // setTask({ ...temp_task });
    } catch (err) {
      console.error(err);
    }

    setFileProgress(0);
  };

  const value = useMemo(() => {
    return {
      data: task,
      file_progress,
      sendMessage,
      editMessage,
      loading,
    };
    // eslint-disable-next-line
  }, [task, file_progress, loading]);

  return (
    <TaskConversationContext.Provider value={value}>
      {children}
    </TaskConversationContext.Provider>
  );
};

const useTaskConversation = () => useContext(TaskConversationContext);
export { TaskConversationContext, useTaskConversation };
export default TaskConversationProvider;
